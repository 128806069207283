export class RouteNames {
    static SelectVariant = 'SelectMembershipVariant';
    static PersonalDetails = 'PersonalDetails';
    static Agreements = 'Agreements';
    static Success = 'Success';
    static Checkout = 'Checkout';
    static Error = 'Checkout';
    static SelectMembershipTest = 'SelectMembershipTest';
    static Intro = 'Intro';
    static Oms1ToOms2Redirect = 'Oms1ToOms2Redirect';
    static SharedMembershipBuilder = 'SharedMembershipBuilder';
}
