/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
declare function require(moduleName: string): any;
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const { version: appVersion } = require('../../package.json');


const fromEnv = (key: string) => {
	const env = (window as any).env;
	if(!(key in env))     {
			throw new Error(`Could not read environment variable ${key}`);
	}
	return env[key];
};

export const environment = {
		production: fromEnv('production')  === 'true',
		apiUrl: fromEnv('apiUrl'),
		paymentApiUrl: fromEnv('paymentApiUrl'),
		seqLoggingEndpoint: fromEnv('seqLoggingEndpoint'),
		seqLoggingApiKey: fromEnv('seqLoggingApiKey'),
		homePageUrl: fromEnv('homePageUrl'),
		siteKey: fromEnv('siteKey'),
		appVersion
};


