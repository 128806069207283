import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-validation-message',
    templateUrl: './validation-message.component.html',
    styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent {
    @Input()
    public control: AbstractControl;

    @Input()
    public error: string;

    public get show() {
        return this.control.invalid && this.control.touched && this.hasAnyErrorToShow();
    }

    private hasAnyErrorToShow() {
        if (!this.error) {
            return true;
        }

        const errorsToShow = this.error.split(',');

        let hasAnyError = false;

        errorsToShow.forEach(x => {
            if (this.control.hasError(x)) {
                hasAnyError = true;
            }
        });

        return hasAnyError;
    }
}
