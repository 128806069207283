import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppConsts } from '../../../core/models/AppConsts';
import { BankAccountType } from '../../../core/models/bank-account.model';
import { TextMaskService } from '@bayclubs/ui-text-mask';


@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.scss']
})
export class BankAccountComponent implements OnInit {
    @Input()
    public form: UntypedFormGroup;

    public accountTypes = [BankAccountType.checking, BankAccountType.saving];

    AppConsts = AppConsts;

    public accountNumberMask = '99999999999999999separator.0';

    constructor(public mask: TextMaskService) {}

    ngOnInit() {}

    isAccountTypeSelected(accountType: BankAccountType) {
        return this.form.controls['accountType'].value === accountType;
    }
}
