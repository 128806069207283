<div *ngIf="isOpen" [ngClass]="{  'position-absolute date-picker-base day-picker-width ml-3': true }">
    <div id="day-picker-row" class="row align-items-center background-color-white" *ngFor="let week of weeksInMonth()">
        <div [ngClass]="{ 'col col-divide-by-7 px-0': true }" *ngFor="let day of week">
            <div [ngClass]="{ 'day-picker-cell d-table-cell align-middle': true, 'selected-cell': isSelected(day),
            'disabled-cell': isDisabled(day), 'hand day-picker-cell-hover hovered-col': !isDisabled(day) }"
                (click)="selectDay(day)">
                <span [ngClass]="{ 'cell-label': true, 'cursor-default disabled-cell': isDisabled(day) }">
                    {{day | date : 'd'}}
                </span>
            </div>
        </div>
    </div>
</div>