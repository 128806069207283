import { Injectable } from '@angular/core';

@Injectable()
export class RoutingExtensions {
    constructor() {}

    public isComingFromTheApp(): boolean {
        return document.referrer.indexOf(location.protocol + '//' + location.host) === 0;
    }
}
