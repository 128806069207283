<div *ngIf="isOpen" class="position-absolute date-picker-base ml-3">
    <div class="row align-items-center month-picker-base">
        <div *ngFor="let month of getMonths()" class="col-4 px-0" (click)="selectMonth(month)">
            <div [ngClass]="{ 'month-picker-cell d-table-cell align-middle': true, 'selected-cell': isSelected(month),
            'disabled-cell': isMonthDisabled(month), 'hand month-picker-cell-hover': !isMonthDisabled(month) }"
                (click)="selectMonth(month)">
                <span [ngClass]="{ 'cell-label': true, 'cursor-default disabled-cell': isMonthDisabled(month) }">
                    {{month | date : 'MMMM'}}
                </span>
            </div>
        </div>
    </div>
</div>