import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-oms-error',
    templateUrl: './oms-error.component.html',
    styleUrls: ['./oms-error.component.scss']
})
export class OmsErrorComponent implements OnInit {
    public text: string;

    public title: string;

    public icon: ErrorIcon;

    constructor(private changeDetector: ChangeDetectorRef) {
        setTimeout(() => {
            this.changeDetector.detectChanges();
        }, 10);
    }

    ngOnInit() {}

    public isCardIcon() {
        return this.icon == ErrorIcon.Card;
    }

    public isWarningIcon() {
        return this.icon == ErrorIcon.Warning;
    }
}

export enum ErrorIcon {
    Warning,
    Card
}
