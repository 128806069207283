import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// application services
import { ApiHttpClient } from './services/api-http-client';
import { OmsErrorHandler } from './services/oms-error-handler';
import { ErrorTranslationService } from './services/error-translation.service';
import { PaymentApiHttpClient } from './services/payment-api-http-client';
import { StateService } from './services/state.service';
import { OmsAlertService } from './services/oms-alert-service';
import { ScrollingService } from './services/scrolling.service';
import { RedirectToHomeUrlGuard } from './services/redirect-to-home-url.guard';
import { RoutingExtensions } from './services/routing-extensions.service.';

// application components
import { ServerErrorComponent } from './components/server-error/server-error.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { OmsErrorComponent } from './components/oms-error/oms-error.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { YesOrNoSelectionComponent } from './components/yes-or-no-selection/yes-or-no-selection.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MonthPickerComponent } from './components/date-picker/month-picker/month-picker.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DayPickerComponent } from './components/date-picker/day-picker/day-picker.component';
import { YearPickerComponent } from './components/date-picker/year-picker/year-picker.component';
import { BankAccountComponent } from './components/bank-account/bank-account.component';
import { BayclubsUiTextMaskModule } from '@bayclubs/ui-text-mask';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ScrollToModule,
        ClickOutsideModule,
        BayclubsUiTextMaskModule,
        NgxMaskDirective
    ],
    declarations: [
        ServerErrorComponent,
        CreditCardComponent,
        ValidationMessageComponent,
        OmsErrorComponent,
        NotFoundComponent,
        YesOrNoSelectionComponent,
        DatePickerComponent,
        MonthPickerComponent,
        DayPickerComponent,
        YearPickerComponent,
        BankAccountComponent
    ],
    exports: [
        ServerErrorComponent,
        CreditCardComponent,
        ValidationMessageComponent,
        NotFoundComponent,
        YesOrNoSelectionComponent,
        DatePickerComponent,
        MonthPickerComponent,
        DayPickerComponent,
        YearPickerComponent,
        BankAccountComponent
    ]
})
export class InfrastructureModule {
    static forRoot(): ModuleWithProviders<InfrastructureModule> {
        return {
            ngModule: InfrastructureModule,
            providers: [
                ApiHttpClient,
                OmsErrorHandler,
                ErrorTranslationService,
                StateService,
                PaymentApiHttpClient,
                OmsAlertService,
                ScrollingService,
                RedirectToHomeUrlGuard,
                RoutingExtensions
            ]
        };
    }
}

// models
export * from './models/state';

// services
export { ApiHttpClient } from './services/api-http-client';
export { OmsErrorHandler } from './services/oms-error-handler';
export { StateService } from './services/state.service';
export { OmsAlertService } from './services/oms-alert-service';
export { PaymentApiHttpClient } from './services/payment-api-http-client';
export { ScrollingService } from './services/scrolling.service';
export { RedirectToHomeUrlGuard } from './services/redirect-to-home-url.guard';
// components
export { CreditCardComponent } from './components/credit-card/credit-card.component';
export { NotFoundComponent } from './components/not-found/not-found.component';
export { BankAccountComponent } from './components/bank-account/bank-account.component';
