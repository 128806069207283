import { Injectable } from '@angular/core';

import { State } from '../models/state';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    constructor() {}

    public getAll(): Array<State> {
        let states = [];

        states.push(new State('AL', 'Alabama'));
        states.push(new State('AK', 'Alaska'));
        states.push(new State('AZ', 'Arizona'));
        states.push(new State('AR', 'Arkansas'));
        states.push(new State('CA', 'California'));
        states.push(new State('CO', 'Colorado'));
        states.push(new State('CT', 'Connecticut'));
        states.push(new State('DE', 'Delaware'));
        states.push(new State('DC', 'District Of Columbia'));
        states.push(new State('FL', 'Florida'));
        states.push(new State('GA', 'Georgia'));
        states.push(new State('HI', 'Hawaii'));
        states.push(new State('ID', 'Idaho'));
        states.push(new State('IL', 'Illinois'));
        states.push(new State('IN', 'Indiana'));
        states.push(new State('IA', 'Iowa'));
        states.push(new State('KS', 'Kansas'));
        states.push(new State('KY', 'Kentucky'));
        states.push(new State('LA', 'Louisiana'));
        states.push(new State('ME', 'Maine'));
        states.push(new State('MD', 'Maryland'));
        states.push(new State('MA', 'Massachusetts'));
        states.push(new State('MI', 'Michigan'));
        states.push(new State('MN', 'Minnesota'));
        states.push(new State('MS', 'Mississippi'));
        states.push(new State('MO', 'Missouri'));
        states.push(new State('MT', 'Montana'));
        states.push(new State('NE', 'Nebraska'));
        states.push(new State('NV', 'Nevada'));
        states.push(new State('NH', 'New Hampshire'));
        states.push(new State('NJ', 'New Jersey'));
        states.push(new State('NM', 'New Mexico'));
        states.push(new State('NY', 'New York'));
        states.push(new State('NC', 'North Carolina'));
        states.push(new State('ND', 'North Dakota'));
        states.push(new State('OH', 'Ohio'));
        states.push(new State('OK', 'Oklahoma'));
        states.push(new State('OR', 'Oregon'));
        states.push(new State('PA', 'Pennsylvania'));
        states.push(new State('RI', 'Rhode Island'));
        states.push(new State('SC', 'South Carolina'));
        states.push(new State('SD', 'South Dakota'));
        states.push(new State('TN', 'Tennessee'));
        states.push(new State('TX', 'Texas'));
        states.push(new State('UT', 'Utah'));
        states.push(new State('VT', 'Vermont'));
        states.push(new State('VA', 'Virginia'));
        states.push(new State('WA', 'Washington'));
        states.push(new State('WV', 'West Virginia'));
        states.push(new State('WI', 'Wisconsin'));
        states.push(new State('WY', 'Wyoming'));

        return states;
    }
}
