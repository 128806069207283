<div class="modal fade server-error-modal" id="appModal" #appModal tabindex="-1" role="dialog">

    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col"><img src="assets/server_error.jpg" /></div>
                    <div class="col error-container pt-4 pl-2">
                        <div class="i-warning-orange"></div>
                        <div class="error-title mt-2">{{'ServerError.Ups' | translate}}</div>
                        <div class="error-subtitle mt-1">{{'ServerError.ErrorInfo' | translate}}</div>
                        <div class="error-info mt-2">{{'ServerError.ErrorDetails' | translate}} <a
                                class="home-page-info" [href]="homeUrl()">{{'ServerError.HomePage' | translate}}</a>
                        </div>
                        <!-- <div data-dismiss="modal" class="btn btn-danger mt-2">{{'General.Close' | translate}}</div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>