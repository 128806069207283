import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimeService } from '@bayclubs/utils';
import * as moment from 'moment';

import { DatePickerModel } from '../date-picker.component';

@Component({
    selector: 'app-month-picker',
    templateUrl: './month-picker.component.html',
    styleUrls: ['./month-picker.component.scss', '../picker-form.component.scss']
})
export class MonthPickerComponent implements OnInit, OnDestroy {
    @Input() isOpen = false;
    @Input() min: moment.Moment;
    @Input() max: moment.Moment;
    @Input() model: DatePickerModel;

    constructor(private timeService: TimeService) {}

    ngOnInit() {}

    ngOnDestroy(): void {}

    public getMonths() {
        const months = [];
        let monthNumber = 0;
        let start = this.model.date.clone().month(monthNumber);

        while (monthNumber < 12) {
            months.push(start);
            start = this.timeService.addMonths(start, 1);
            monthNumber++;
        }

        return months;
    }

    public isSelected(date: moment.Moment): boolean {
        return this.model.month === date.month();
    }

    isMonthDisabled(date: moment.Moment): boolean {
        return this.isSelectedMinorMonthThanMinMarginal(date) || this.isSelectedGreaterMonthThanMaxMarginal(date);
    }

    isDayDisabled(date: moment.Moment): boolean {
        return this.isSelectedMinorDayThanMinMarginal(date) || this.isSelectedGreaterDayThanMaxMarginal(date);
    }

    isSelectedGreaterMonthThanMaxMarginal(date: moment.Moment): boolean {
        return this.model.year && this.model.year === this.max.year() && date.month() > this.max.month();
    }

    isSelectedMinorMonthThanMinMarginal(date: moment.Moment): boolean {
        return this.model.year && this.model.year === this.min.year() && this.min.month() > date.month();
    }

    isSelectedGreaterDayThanMaxMarginal(date: moment.Moment): boolean {
        return (
            this.model.year &&
            this.model.year === this.max.year() &&
            date.month() === this.max.month() &&
            date.date() > this.max.date()
        );
    }

    isSelectedMinorDayThanMinMarginal(date: moment.Moment): boolean {
        return (
            this.model.year &&
            this.model.year === this.min.year() &&
            this.min.month() === date.month() &&
            this.min.date() > date.date()
        );
    }
    selectMonth(date: moment.Moment): void {
        if (this.isMonthDisabled(date)) {
            return;
        }

        this.model.date = date.clone();
        this.model.month = this.model.date.month();

        this.clearCells();
    }

    clearCells(): void {
        if (this.model.day && (this.model.date.daysInMonth() < this.model.day || this.isDayDisabled(this.model.date))) {
            this.model.day = null;
            this.model.date = this.timeService.startOfMonth(this.model.date);
            if (this.model.day) {
                this.model.date = this.timeService.addDays(this.model.date, this.model.day - 1);
            }
        }
    }
}
