import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RedirectToHomeUrlGuard implements CanActivate {
    constructor() {}

    canActivate() {
        window.location.href = environment.homePageUrl;
        return false;
    }
}
