import { Component, Input, OnInit } from '@angular/core';
import { TimeService } from '@bayclubs/utils';
import * as moment from 'moment';

import { DatePickerModel } from '../date-picker.component';

@Component({
    selector: 'app-day-picker',
    templateUrl: './day-picker.component.html',
    styleUrls: ['./day-picker.component.scss', '../picker-form.component.scss']
})
export class DayPickerComponent implements OnInit {
    @Input() isOpen = false;
    @Input() min: moment.Moment;
    @Input() max: moment.Moment;
    @Input() model: DatePickerModel;

    constructor(private timeService: TimeService) {}

    ngOnInit() {}

    public weeksInMonth() {
        const weeks = [];
        let daysInWeek: Array<moment.Moment> = [];
        let start = this.timeService.startOfMonth(this.model.date);
        const end = this.timeService.endOfMonth(this.model.date);

        while (start <= end) {
            daysInWeek.push(start);
            start = this.timeService.addDays(start, 1);

            if (start.date() / 6 === 0) {
                weeks.push(daysInWeek);
                daysInWeek = [];
            }
        }

        weeks.push(daysInWeek);
        return weeks;
    }

    public selectDay(date: moment.Moment): void {
        if (this.isDisabled(date)) {
            return;
        }

        this.model.date = date.clone();
        this.model.day = this.model.date.date();
    }

    public isSelected(date: moment.Moment): boolean {
        return this.model.day === date.date();
    }

    public isDisabled(date: moment.Moment): boolean {
        return this.model.month != null && this.model.year != null && (date.isBefore(this.min) || date.isAfter(this.max));
    }
}
