import { ErrorHandler, Injectable, Injector, NgModuleRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, Route } from '@angular/router';
import * as StackTrace from 'stacktrace-js';

import { ModalService, IdentityProvider } from '@bayclubs/utils';
import { AppLogService } from '@bayclubs/logging';

import { ServerErrorComponent } from '../components/server-error/server-error.component';
import { OmsAlertService } from './oms-alert-service';
import { ErrorCode } from '../models/error-code';
import { NavigationService } from '../../core/services/navigation/navigation.service';
import { ApiErrorResult } from '../models/api-model';

@Injectable({
    providedIn: 'root'
})
export class OmsErrorHandler implements ErrorHandler {
    private router: Router;

    constructor(
        private injector: Injector,
        private modal: ModalService,
        private moduleRef: NgModuleRef<any>,
        private alert: OmsAlertService,
        private navigationService: NavigationService
    ) {}

    handleError(error) {
        this.init();

        if (this.shouldLogError(error)) {
            this.logError(error);
        } else {
            console.log('Skipping logging error');
        }

        if (error instanceof HttpErrorResponse) {
            if (error.status === 404) {
                this.navigationService.goTo404();
            }
            if (error.status === 400) {
                if (this.isApiError(error.error)) {
                    const errorCode = this.getApiErrorCode(error.error);
                    if (this.shouldRedirectToMemberErrorPage(errorCode)) {
                        this.navigationService.goToMemberErrorPage(errorCode);
                    } else {
                        this.alert.showApiError(error.error);
                    }
                } else {
                    this.alert.showUnexpectedError();
                }
            }
            if (error.status === 500) {
                if (this.modal.instances.length > 0) {
                    if (!(this.modal.instances[0] instanceof ServerErrorComponent)) {
                        this.modal.closeAll().subscribe(() => {
                            this.modal.create<ServerErrorComponent>(ServerErrorComponent, {}, this.moduleRef).subscribe();
                        });
                    }
                } else {
                    this.modal.create<ServerErrorComponent>(ServerErrorComponent, {}, this.moduleRef).subscribe();
                }
            }
        } else {
            throw error;
        }
    }

    private extractCorrelationId(error: HttpErrorResponse) {
        if (error && error.error) {
            return error.error.CorrelationId;
        }
    }

    private init() {
        if (this.router == null) {
            this.router = this.injector.get(Router);
        }
    }

    private isApiError(httpError: any): httpError is ApiErrorResult {
        return (<ApiErrorResult>httpError).Errors !== undefined;
    }

    private getApiErrorCode(error: ApiErrorResult): string {
        if (!error || !error.Errors || error.Errors.length === 0) {
            return null;
        }

        return error.Errors[0].Code;
    }

    private shouldLogError(error: any): boolean {
        if (error instanceof HttpErrorResponse) {
            if (error.status >= 400 && error.status < 500) {
                return false;
            }
        }

        return true;
    }

    private shouldRedirectToMemberErrorPage(errorCode: string) {
        return (
            errorCode === ErrorCode.CartIsNoLongerAvailable ||
            errorCode === ErrorCode.AddonAgreementsAlreadySigned ||
            errorCode === ErrorCode.EntityNotFound
        );
    }

    private logError(error): void {
        const loggingService = this.injector.get(AppLogService);
        const message = error.message ? error.message : error.toString();
        const identity = IdentityProvider.emptyIdentity;

        if (error instanceof HttpErrorResponse) {
            StackTrace.get().then(x => {
                const stringifiedStack = x
                    .map(function(sf) {
                        return sf.toString();
                    })
                    .join('\n');

                loggingService.error(message, identity, stringifiedStack, this.extractCorrelationId(error));
            });
        } else {
            StackTrace.fromError(error).then(stackframes => {
                const stackString = stackframes
                    .splice(0, 120)
                    .map(function(sf) {
                        return sf.toString();
                    })
                    .join('\n');
                loggingService.error(message, identity, stackString);
            });
        }
    }
}
