<div [ngClass]="{ 'row pr-3': true, 'pointer-events-none': disabled }">
    <div class="col-6 pr-1">
        <div [ngClass]="{ 'form-control hand px-2': true, 'date-picker-invalid': ngControl.touched && model.month == null,
        'form-control-active': isMonthPickerOpened }" (click)="openMonthPicker()"
            (clickOutside)="closeMonthPicker($event)" tabindex=0>
            <span [ngClass]="{ 'date-label': true, 'selected-label': model.month !== null }">
                {{ getMonthTitle() }}
            </span>
            <img class="float-right pt-2" [src]="'./assets/ic_chevron.svg'">
        </div>
    </div>
    <div class="col-3 px-1">
        <div [ngClass]="{ 'form-control hand px-2': true, 'date-picker-invalid': ngControl.touched && !model.day, 'form-control-active': isDayPickerOpened }"
            (click)="openDayPicker()" (clickOutside)="closeDayPicker($event)" tabindex=0>
            <span [ngClass]="{ 'date-label': true, 'selected-label': model.day !== null }">
                {{ getDayTitle() }}
            </span>
            <img class="float-right pt-2" [src]="'./assets/ic_chevron.svg'">
        </div>
    </div>
    <div class="col-3 pl-1 pr-0">
        <div [ngClass]="{ 'form-control hand px-2': true, 'date-picker-invalid': ngControl.touched && !model.year, 'form-control-active': isYearPickerOpened }"
            (click)="openYearPicker()" (clickOutside)="closeYearPicker($event)" tabindex=0>
            <span [ngClass]="{ 'date-label': true, 'selected-label': model.year !== null }">
                {{ getYearTitle() }}
            </span>
            <img class="float-right pt-2" [src]="'./assets/ic_chevron.svg'">
        </div>
    </div>
</div>
<app-month-picker [isOpen]="isMonthPickerOpened" [min]="minMoment" [max]="maxMoment" [model]="model">
</app-month-picker>
<app-day-picker [isOpen]="isDayPickerOpened" [min]="minMoment" [max]="maxMoment" [model]="model">
</app-day-picker>
<app-year-picker [isOpen]="isYearPickerOpened" [min]="minMoment" [max]="maxMoment" [model]="model">
</app-year-picker>