export class AppConsts {
    static readonly maxChildrenInFamilyMembership: number = 8;

    static readonly memberValidation = {
        maxFirstNameLength: 40,
        maxLastNameLength: 80,
        maxEmailLength: 80,
        maxPhoneLength: 40,
        maxAddressLength: 100,
        maxCityLength: 40,
        maxStateLength: 20,
        ageOfMajority: 18
    };

    static readonly chaseFieldsLimits = {
        addressMaxLength: 30,
        cityMaxLength: 20
    };

    static readonly cmPlusFieldsLimits = {
        bankAccountNumberMaxLength: 17
    };
}
