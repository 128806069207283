import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiErrorResult, ApiError } from '../models/api-model';

@Injectable()
export class ErrorTranslationService {
    constructor(private translateService: TranslateService) {}

    public getTranslatedMessage(response: ApiErrorResult): string {
        this.translateResponse(response);

        let msg = '';
        response.Errors.forEach(error => {
            msg += error.Message + ' ';
        });

        return msg;
    }

    public translateResponse(response: ApiErrorResult) {
        response.Errors.forEach(error => {
            this.translateError(error, response.Source);
        });
    }

    public translateError(error: ApiError, source: string) {
        if (error.Code === 'MissingRequiredField' || error.Code === 'PredicateValidator' || error.Code === 'GeneralError') {
            error.Message = this.getMessage(error, this.getKey(source, error.Code)) + ' ' + error.Message;
        } else {
            error.Message = this.getMessage(error, this.getKey(source, error.Code));
        }
    }

    public translateErrorByKey(source: string, key: string): string {
        const msgKey = this.getKey(source, key);
        const msg = this.translateService.instant(msgKey);
        return msg;
    }

    private getKey(source: string, key: string) {
        return 'Error-' + source + '.' + key;
    }

    private getMessage(error: ApiError, key: string): string {
        let msg = this.translateService.instant(key);

        if (msg && error.Arguments && error.Arguments.length > 0) {
            for (let i = 0; i < error.Arguments.length; i++) {
                msg = msg.replace('{' + i + '}', error.Arguments[i].toString());
            }
        }

        return msg;
    }
}
