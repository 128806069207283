<div class="modal fade oms-error-modal" id="appModal" #appModal tabindex="-1" role="dialog">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <span class="i-close" data-dismiss="modal"></span>
            <div class="modal-header">
                <span> {{title}}</span>
            </div>

            <div class="modal-body">

                <div class="icon i-error-card" *ngIf="isCardIcon()"></div>
                <div class="icon i-warning" *ngIf="isWarningIcon()"></div>
                <div class="error-text"> {{text}}</div>
            </div>
        </div>
    </div>
</div>