import { Component, OnInit, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateConsts } from './date-consts';
import { TimeService, utils } from '@bayclubs/utils';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
    constructor(
        private translateService: TranslateService,
        private timeService: TimeService,
        @Optional() @Self() public ngControl: NgControl
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    model = <DatePickerModel>{
        day: null,
        month: null,
        year: null
    };

    _max = this.timeService.toBcDate(new Date(DateConsts.defaultMaxYear, 0, 1));
    _min = this.timeService.toBcDate(new Date(DateConsts.defaultMinYear, 0, 1));
    private _value?: utils.BcDate;
    isMonthPickerOpened = false;
    isDayPickerOpened = false;
    isYearPickerOpened = false;

    @Input() disabled = false;

    get value(): utils.BcDate {
        return this._value;
    }

    set value(value: utils.BcDate) {
        if (!this.disabled) {
            this._value = value;
            this.onChange(this.value);
        }
    }

    get max(): utils.BcDate {
        return this._max;
    }

    @Input('max')
    set max(value: utils.BcDate) {
        if (!value || this.isMaxValueSmallerThanMin(value)) {
            this._max = this.timeService.toBcDate(new Date(DateConsts.defaultMaxYear, 1, 1));
        } else {
            this._max = value;
        }
    }

    get min(): utils.BcDate {
        return this._min;
    }

    @Input('min')
    set min(value: utils.BcDate) {
        if (!value || this.isMinValueBiggerThanMax(value)) {
            this._min = this.timeService.toBcDate(new Date(DateConsts.defaultMinYear, 1, 1));
        } else {
            this._min = value;
        }
    }

    isMinValueBiggerThanMax(value: utils.BcDate): boolean {
        return this._max && this.timeService.toMoment(value) > this.timeService.toMoment(this._max);
    }

    isMaxValueSmallerThanMin(value: utils.BcDate): boolean {
        return this.min && this.timeService.toMoment(this._min) > this.timeService.toMoment(value);
    }

    get maxMoment(): moment.Moment {
        return this.timeService.toMoment(this._max);
    }

    get minMoment(): moment.Moment {
        return this.timeService.toMoment(this._min);
    }

    ngOnInit() {
        this.setStartDate();
    }

    setStartDate(): void {
        const today = moment();
        this.model.date =
            this.min && this.minMoment > this.timeService.startOfYear(today)
                ? this.timeService.startOfMonth(this.minMoment.add(1, 'months'))
                : this.max && this.timeService.startOfYear(today) > this.maxMoment
                    ? this.timeService.startOfMonth(this.maxMoment.subtract(1, 'month'))
                    : this.timeService.startOfYear(today);
    }

    onChange = (value: utils.BcDate) => { };
    onTouched = () => { };

    getMonthTitle(): string {
        return this.model.month !== null ? moment.months(this.model.month) : this.translateService.instant('Date.Month');
    }

    openMonthPicker(): void {
        this.isMonthPickerOpened = !this.isMonthPickerOpened;
    }

    closeMonthPicker(event: MouseEvent): void {
        if (this.isDisabledCellClicked(event)) {
            return;
        }

        this.isMonthPickerOpened = false;
        this.writeValue(null);
    }

    getDayTitle(): string {
        return this.model.day !== null ? this.model.day : this.translateService.instant('Date.Day');
    }

    openDayPicker(): void {
        this.isDayPickerOpened = !this.isDayPickerOpened;
    }

    closeDayPicker(event: any): void {
        if (this.isDisabledCellClicked(event) || this.isDayPickerClickedOutsideOfCell(event)) {
            return;
        }

        this.isDayPickerOpened = false;
        this.writeValue(null);
    }

    isDayPickerClickedOutsideOfCell(event: any): boolean {
        return event.target && event.target.id === 'day-picker-row';
    }

    getYearTitle(): string {
        return this.model.year !== null ? this.model.year : this.translateService.instant('Date.Year');
    }

    getMinYear(): number {
        return this.timeService.toMoment(this.min).year();
    }

    getMaxYear(): number {
        return this.timeService.toMoment(this.max).year();
    }

    openYearPicker(): void {
        this.isYearPickerOpened = !this.isYearPickerOpened;
    }

    closeYearPicker(event: any): void {
        if (this.isDisabledCellClicked(event) || this.isLeftOrRightYearColumnClicked(event)) {
            return;
        }
        this.isYearPickerOpened = false;
        this.writeValue(null);
    }

    isLeftOrRightYearColumnClicked(event: any): boolean {
        return (
            (event.target.lastChild && event.target.lastChild.id && event.target.lastChild.id === 'right-year-column') ||
            (event.target.id && event.target.id === 'right-year-button') ||
            (event.target.id && event.target.id === 'right-year-column') ||
            (event.target.lastChild && event.target.lastChild.id && event.target.lastChild.id === 'left-year-column') ||
            (event.target.id && event.target.id === 'left-year-button') ||
            (event.target.id && event.target.id === 'left-year-column')
        );
    }

    isDisabledCellClicked(event: any): boolean {
        return event.target.classList && event.target.classList.contains('disabled-cell');
    }

    writeValue(value: utils.BcDate): void {
        if (this.value && (value && !value.value)) {
            this.resetModel();
            this.value = null;
            return;
        }

        value == null || value.value == null ? this.setDateFromPicker() : this.setDateAtStart(value);
    }

    resetModel(): void {
        this.model = <DatePickerModel>{
            day: null,
            month: null,
            year: null
        };
        this.setStartDate();
    }

    setDateAtStart(value: utils.BcDate): void {
        this.value = value;
        const momentDate = this.timeService.toMoment(value);
        this.model.month = momentDate.month();
        this.model.day = momentDate.date();
        this.model.year = momentDate.year();
        this.model.date = momentDate;
    }

    setDateFromPicker(): void {
        if (this.model.day != null && this.model.month != null && this.model.year != null) {
            this.value = this.timeService.toBcDate(new Date(this.model.year, this.model.month, this.model.day));
        } else {
            this.value = null;
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

export interface DatePickerInputModel {
    date: utils.BcDate;
    resetData: boolean;
}

export class DatePickerModel {
    month: number;
    day: number;
    year: number;
    date: moment.moment;
}
