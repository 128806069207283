import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-server-error',
    templateUrl: './server-error.component.html',
    styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent {
    constructor(private changeDetector: ChangeDetectorRef) {
        setTimeout(() => {
            console.log('detecting changes');
            this.changeDetector.detectChanges();
        }, 10);
    }

    public homeUrl() {
        return environment.homePageUrl;
    }
}
