import { Injectable, NgModuleRef } from '@angular/core';
import { ModalService } from '@bayclubs/utils';
import { ErrorIcon, OmsErrorComponent } from '../components/oms-error/oms-error.component';
import { ErrorTranslationService } from './error-translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiErrorResult } from '../models/api-model';

@Injectable({
    providedIn: 'root'
})
export class OmsAlertService {
    constructor(
        private modal: ModalService,
        private moduleRef: NgModuleRef<any>,
        private errorTranslation: ErrorTranslationService,
        private translateService: TranslateService
    ) {}

    public showTextError(text: string, icon: ErrorIcon = ErrorIcon.Warning) {
        this.modal.create<OmsErrorComponent>(OmsErrorComponent, { text: text, icon: icon }, this.moduleRef).subscribe();
    }

    public showError(textResource: string, icon: ErrorIcon = ErrorIcon.Warning) {
        const text = this.translateService.instant(textResource);

        this.showTextError(text, icon);
    }

    public showApiError(error: ApiErrorResult, icon: ErrorIcon = ErrorIcon.Warning) {
        const text = this.errorTranslation.getTranslatedMessage(error);

        this.showTextError(text, icon);
    }

    public showUnexpectedError(icon: ErrorIcon.Warning = null) {
        this.showError('General.UnexpectedError', icon);
    }
}
