<div *ngIf="isOpen" class="position-absolute date-picker-base year-picker-lg-options float-right">
    <div class="row align-items-center">
        <div id="left-year-column" [ngClass]="{ 'col-2 text-center hand py-3': true, 'invisible': !isPreviousPage }"
            (click)="undoPage()"><img id="left-year-button" class="left-year-button" [src]="'./assets/ic_chevron.svg'">
        </div>
        <div class="col-8 px-0">
            <div class="row align-items-center">
                <div [ngClass]="{ 'col-3 year-col-base px-0': true, 'selected-col': isSelected(year), 'disabled-cell': isYearDisabled(year), 'hovered-col': !isYearDisabled(year) }"
                    *ngFor="let year of years">
                    <div [ngClass]="{ 'year-picker-cell d-table-cell align-middle': true, 'selected-cell': isSelected(year),
                    'disabled-cell': isYearDisabled(year), 'hand day-picker-cell-hover': !isYearDisabled(year), 'cursor-default': isYearDisabled(year) }"
                        (click)="selectYear(year)">
                        <span class="cell-label">{{ year | date : 'yyyy' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="right-year-column" [ngClass]="{ 'col-2 text-center hand py-3': true, 'invisible': !isNextPage }"
            (click)="addPage()"><img id="right-year-button" class="right-year-button" [src]="'./assets/ic_chevron.svg'">
        </div>
    </div>
</div>