import { Injectable, Component } from '@angular/core';
import { Router, Params, ActivationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { WizardStatus } from './navigation-model/wizard-status';
import { WizardStep } from './navigation-model/wizard-step';
import { SiteFlow } from './navigation-model/site-flow';

@Injectable({
    providedIn: 'root'
})
export abstract class NavigationService {
    actionThrottle = new Subject<() => void>();

    constructor(protected router: Router, protected location: Location) {
        this.router.events.subscribe(e => {
            if (e instanceof ActivationEnd) {
                if (!e.snapshot.component) {
                    return;
                }

                this.updateParams(e.snapshot.params);
                this.updateQueryParams(e.snapshot.queryParams);

                this.wizard.steps.forEach(step => {
                    if (this.isStepActive(step, e.snapshot.data['route'])) {
                        this.wizard.markStepActive(step);
                    }
                });
            }
        });

        this.actionThrottle.pipe(throttleTime(1000)).subscribe(action => {
            action();
        });
    }

    public wizard: WizardStatus = new WizardStatus();

    public typeCode: string;

    public groupCode: string;

    public variantCode: string;

    public purchaseId: string;

    public cartId: string;

    public customerId: string;

    public leadId: string;

    public contactId: string;

    public siteFlow: SiteFlow;

    public club: string;

    public campus: string;

    public fullUrl: string;

    public gclid: string;

    public mkt_tok: string;

    public saleDate: number;

    public baseUrl: string;

    abstract initSteps(): void;

    public updateParams(params: any) {
        this.groupCode = params.groupCode;
        this.typeCode = params.typeCode;
        this.variantCode = params.variantCode;
        this.cartId = params.cartId;
        this.customerId = params.customerId;
        this.leadId = params.leadId;
        this.contactId = params.contactId;
    }

    public updateQueryParams(params: Params) {
        this.club = params.club;
        this.campus = params.campus;
        this.fullUrl = encodeURI(window.location.href);
        this.gclid = params.gclid;
        this.mkt_tok = params.mkt_tok;
        this.saleDate = params.saleDate;
    }

    public activeStepNumber(): number {
        if (!this.wizard.activeStep) {
            return -1;
        }
        return this.wizard.activeStep.stepNo;
    }

    public goNext(clearHistory: boolean = false) {
        const step = this.wizard.getNextStep();
        if (step) {
            this.actionThrottle.next(() => {
                this.navigateTo(step.url(), clearHistory);
            });
        }
    }

    public goToFirstStep() {
        const step = this.wizard.steps[0];
        if (step) {
            this.navigateTo(step.url());
        }
    }

    public goToStep(number: any) {
        const stepToNavigate = this.wizard[number];
        if (stepToNavigate) {
            this.actionThrottle.next(() => {
                this.navigateTo(stepToNavigate.url());
            });
        }
    }

    public goBack() {
        const step = this.wizard.getPrevStep();
        if (step) {
            this.actionThrottle.next(() => {
                this.navigateTo(step.url());
            });
        }
    }

    public canGoBack(): boolean {
        return this.wizard.canGoBack();
    }

    public goTo404() {
        this.navigateTo('404');
    }

    public goToMemberErrorPage(errorCode: string = null) {
        this.router.navigate(['error', errorCode]);
    }

    protected concatUrl(...items: string[]): string {
        return items.join('/');
    }

    protected navigateTo(url: string, clearHistory: boolean = false): void {
        console.log('Navigating to ' + url);

        const navigateAction = () => {
            this.router.navigate([url], { queryParamsHandling: 'merge' });
        };

        if (clearHistory) {
            setTimeout(() => {
                this.location.replaceState('');
                navigateAction();
            }, 1);
        } else {
            navigateAction();
        }
    }

    private isStepActive(step: WizardStep, activeRoute: string): boolean {
        return step.route === activeRoute;
    }
}
