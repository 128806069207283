import { Injectable, Inject } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScrollingService {
    constructor(private scrollToService: ScrollToService, @Inject(DOCUMENT) private _document: any) {}

    public scrollToElement(elementId: string, addedOffset = 0): void {
        let offset = this.calculateOffset(elementId);
        offset -= addedOffset;
        this.scrollTo(offset);
    }

    private calculateOffset(elementId: string): number {
        const node = this.getNode(elementId);
        if (node) {
            return node.getBoundingClientRect().top;
        }
        return 0;
    }

    private scrollTo(offset: number) {
        const config: ScrollToConfigOptions = {
            duration: 650,
            easing: 'easeOutElastic',
            offset: offset
        };
        this.scrollToService.scrollTo(config);
    }

    private getNode(id: string): HTMLElement {
        return this._document.getElementById(id);
    }
}
