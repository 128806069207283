import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

//bayclubs modules
import { LoaderInterceptor, UtilsModule, TranslationService } from '@bayclubs/utils';
import { AlertModule } from '@bayclubs/alert';
import { SpinnerModule } from '@bayclubs/spinner';
import { DropDownModule } from '@bayclubs/drop-down';
import { LoggingModule, LoggingInterceptor, CorrelationIdHttpInterceptor } from '@bayclubs/logging';

//application modules
import { AppRoutingModule } from './app-routing.module';
import { InfrastructureModule } from './infrastructure/infrastructure.module';

//application components
import { AppComponent } from './app.component';

import { OmsErrorHandler } from './infrastructure/infrastructure.module';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        UtilsModule.forRoot(),
        LoggingModule.forRoot({
            loggingConfig: {
                consoleLogEnabled: !environment.production,
                seqLoggingEndpoint: environment.seqLoggingEndpoint,
                seqLoggingApiKey: environment.seqLoggingApiKey,
                appVersion: environment.appVersion
            }
        }),
        InfrastructureModule.forRoot(),
        ScrollToModule.forRoot(),
        AlertModule.forRoot(),
        SpinnerModule.forRoot(),
        DropDownModule.forRoot()
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initTranslations,
            deps: [TranslationService],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: ErrorHandler, useClass: OmsErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'localization/', '.json?timestamp=' + new Date().getTime());
}

export function initTranslations(translationService: TranslationService) {
    return translationService.initSynchronously('locale-en');
}
