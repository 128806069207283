import { RouteNames } from '../../../route-names';
import { WizardStep } from './wizard-step';
export class WizardStatus {
    public steps: WizardStep[];
    public activeStep: WizardStep;

    constructor() {
        this.steps = [];
    }
    public canGoBack(): boolean {
        return !!this.getPrevStep();
    }
    public canGoNext(): boolean {
        return !!this.getNextStep();
    }
    public getNextStep(): WizardStep {
        if (!this.activeStep) {
            return null;
        }
        return this[this.activeStep.stepNo + 1];
    }
    public getPrevStep(): WizardStep {
        if (!this.activeStep) {
            return null;
        }
        return this[this.activeStep.stepNo - 1];
    }
    public addSteps(...items: WizardStep[]): void {
        this.steps = items;
        this.activeStep = null;
        items.forEach(i => {
            this[i.stepNo] = i;
        });
    }
    public markStepActive(step: WizardStep) {
        this.activeStep = step;
    }

    public get navigationSteps() {
        if (!this.steps) {
            return [];
        }

        return this.steps.filter(x => !x.isHiddenInNavigation);
    }

    public get hasAgreementsStep() {
        return !!this.navigationSteps.find(x => x.route === RouteNames.Agreements);
    }
}
