import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { BaseHttpClient, BaseHttpParams, TriStateCheckboxComponent } from '@bayclubs/utils';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentApiHttpClient extends BaseHttpClient {
    constructor(httpClient: HttpClient) {
        super(httpClient);
        console.log('Creating payment api http client');
    }

    getUrl(): string {
        return environment.paymentApiUrl;
    }

    postTrusted<T>(url: string, data: any, captcha: string, showLoader: boolean = false, loadingContent: string = null) {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', '*/*')
            .append('Cache-Control', 'no-cache')
            .append('Pragma', 'no-cache')
            .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            .append('x-captcha', captcha);

        return this.httpClient
            .post<T>(this.getUrl() + url, data, {
                headers: headers,
                params: new BaseHttpParams(showLoader, loadingContent)
            })
            .pipe(
                map((response: T) => {
                    return response;
                })
            );
    }
}
