<div class="row no-gutters">
    <div class="col col-12 col-md-6 pr-md-2">
        <div class="form-group">
            <label class="size-14 mb-0">{{'BankAccount.NameOnAccount' | translate}}</label>
            <input id="nameOnAccount" name="nameOnAccount" [formControl]="form.controls['nameOnAccount']" type="text"
                class="form-control" maxlength="200" />
            <app-validation-message [control]="form.controls['nameOnAccount']" [error]="'required'">{{
				'BankAccount.NameOnAccountRequiredErrorMessage' | translate }}</app-validation-message>
        </div>
    </div>
    <div class="col col-12 col-md-6 pl-md-2">
        <div class="form-group">
            <label class="size-14 mb-0">{{'BankAccount.AccountNumber' | translate}}</label>
            <input type="text" id="accountNumber" name="accountNumber" [formControl]="form.controls['accountNumber']"
                type="text" class="form-control"
                [attr.maxlength]="AppConsts.cmPlusFieldsLimits.bankAccountNumberMaxLength"
                [mask]="accountNumberMask" [allowNegativeNumbers]="false" [leadZero]="true"/>
            <app-validation-message [control]="form.controls['accountNumber']" [error]="'required'">{{
				'BankAccount.AccountNumberRequiredErrorMessage' | translate }}</app-validation-message>

            <app-validation-message [control]="form.controls['accountNumber']" [error]="'invalidBankAccount'">{{
					'BankAccount.InvalidAccountNumberErrorMessage' | translate }}</app-validation-message>

        </div>
    </div>
</div>
<div class="row no-gutters">
    <div class="col col-12 col-md-6 pr-md-2">
        <div class="form-group">
            <label class="size-14 mb-0">{{'BankAccount.RoutingNumber' | translate}}</label>
            <input type="text" id="routingNumber" name="routingNumber" [formControl]="form.controls['routingNumber']"
                type="text" class="form-control" [mask]="mask.routingMask" bcMaskGuide/>
            <app-validation-message [control]="form.controls['routingNumber']" [error]="'required'">{{
				'BankAccount.RoutingNumberRequiredErrorMessage' | translate }}</app-validation-message>

            <app-validation-message [control]="form.controls['routingNumber']" [error]="'invalidRoutingNumber'">{{
					'BankAccount.InvalidRoutingNumberErrorMessage' | translate }}</app-validation-message>
        </div>
    </div>
    <div class="col col-12 col-md-6 pl-md-2">
        <div class="form-group">
            <label class="size-14 mb-0 text-nowrap">{{'BankAccount.SelectType' | translate}}</label>
            <div class="form-group m-0 d-flex align-items-center">
                <label *ngFor="let accountType of accountTypes; let first = first" class="hand text-nowrap m-0 pt-2"
                    [ngClass]="{'mr-5': first}">
                    <input id="accountType" name="accountType" name="bank-account-type" class="hand" type="radio"
                        [value]="accountType" [hidden]="true" [formControl]="form.controls['accountType']">
                    <span>
                        <span class="i-radio-on" *ngIf="isAccountTypeSelected(accountType)"></span>
                        <span class="i-radio-off" *ngIf="!isAccountTypeSelected(accountType)"></span>
                        <span> {{'BankAccount.'+ accountType | translate}}</span>
                    </span>

                </label>
            </div>
        </div>
    </div>

</div>
