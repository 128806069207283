import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TimeService } from '@bayclubs/utils';
import * as moment from 'moment';

import { DateConsts } from '../date-consts';
import { DatePickerModel } from '../date-picker.component';

@Component({
    selector: 'app-year-picker',
    templateUrl: './year-picker.component.html',
    styleUrls: ['./year-picker.component.scss', '../picker-form.component.scss']
})
export class YearPickerComponent implements OnInit, OnChanges {
    @Input() isOpen = false;
    @Input() min = this.timeService.toMoment(new Date(DateConsts.defaultMinYear, 0, 1));
    @Input() max = this.timeService.toMoment(new Date(DateConsts.defaultMaxYear, 0, 1));
    @Input() model: DatePickerModel;

    years: Array<moment.Moment> = [];

    private yearsPerPage = 16;
    private isNextPage = true;
    private isPreviousPage = true;
    private lastYearInMatrix: moment.Moment;

    get startYearInMatrix(): moment.Moment {
        return this.timeService.addYears(this.lastYearInMatrix, -this.yearsPerPage + 1);
    }

    constructor(private timeService: TimeService) {}

    ngOnInit() {
        this.lastYearInMatrix = this.max;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isOpen']) {
            this.lastYearInMatrix = this.getLastYearInCurrentMatrix();
            this.calculateYears();
        }
    }

    getLastYearInCurrentMatrix(): moment.Moment {
        if (!this.model.year) {
            return this.getLastYearAdjustedToCurrentMatrixModel();
        }

        let lastYearInCurrentMatrix = this.getLastYearAdjustedToCurrentMatrixModel();
        lastYearInCurrentMatrix = this.iterateYearsToSelectedOne(lastYearInCurrentMatrix);
        return lastYearInCurrentMatrix;
    }

    getLastYearAdjustedToCurrentMatrixModel(): moment.Moment {
        let lastYearInCurrentMatrix = this.lastYearInMatrix ? this.lastYearInMatrix.clone() : null;
        if (!lastYearInCurrentMatrix) {
            lastYearInCurrentMatrix = this.timeService.startOfYear(this.max);
        }
        if (this.model.month != null) {
            lastYearInCurrentMatrix = this.timeService.startOfYear(lastYearInCurrentMatrix);
            lastYearInCurrentMatrix = this.timeService.addMonths(lastYearInCurrentMatrix, this.model.month);
        }
        if (this.model.day != null) {
            lastYearInCurrentMatrix = this.timeService.startOfMonth(lastYearInCurrentMatrix);
            lastYearInCurrentMatrix = this.timeService.addDays(lastYearInCurrentMatrix, this.model.day - 1);
        }

        return lastYearInCurrentMatrix;
    }

    iterateYearsToSelectedOne(lastYearInCurrentMatrix: moment.Moment): moment.Moment {
        while (this.model.year > lastYearInCurrentMatrix.year()) {
            lastYearInCurrentMatrix = this.timeService.addYears(lastYearInCurrentMatrix, this.yearsPerPage);
        }
        while (lastYearInCurrentMatrix.year() - this.yearsPerPage >= this.model.year) {
            lastYearInCurrentMatrix = this.timeService.addYears(lastYearInCurrentMatrix, -this.yearsPerPage);
        }
        return lastYearInCurrentMatrix;
    }

    public calculateYears(): moment.Moment[] {
        this.years = [];
        let start = this.startYearInMatrix;
        const end = this.lastYearInMatrix;

        while (start <= end) {
            if (this.is29February() && start.year() % 4 === 0) {
                start = this.timeService.addDays(start, -1);
            }
            this.years.push(start);
            if (this.is29February() && start.year() % 4 === 0) {
                start = this.timeService.addDays(start, 1);
            }
            start = this.timeService.addYears(start, 1);
        }

        const minShowingYear = Math.min(...this.years.map(x => x.year()));
        const maxShowingYear = Math.max(...this.years.map(x => x.year()));

        this.isNextPage = maxShowingYear < this.max.year();
        this.isPreviousPage = this.min.year() < minShowingYear;

        return this.years;
    }

    public selectYear(newDate: moment.Moment): void {
        if (this.isYearDisabled(newDate)) {
            return;
        }

        this.model.date = newDate.clone();
        this.model.year = this.model.date.year();
        this.clearCells(newDate);
    }

    clearCells(newDate: moment.Moment): void {
        if (this.model.month != null && (this.isSelectedMinorMonthThanMinMarginal() || this.isSelectedGreaterMonthThanMaxMarginal())) {
            this.model.month = null;
            this.model.date = this.timeService.startOfYear(this.model.date);
            if (this.model.day) {
                this.model.date = this.timeService.addDays(this.model.date, this.model.day - 1);
            }
        }
        if (this.model.day != null && (this.model.date.daysInMonth() < this.model.day || this.isDayDisabled(this.model.date))) {
            this.model.day = null;
            this.model.date = this.timeService.startOfMonth(this.model.date);
        } else if (this.is29February() && newDate.year() % 4 !== 0) {
            this.model.day = null;
            this.model.date = this.timeService.startOfMonth(this.model.date).subtract(1, 'month');
        }
    }

    is29February(): boolean {
        return this.model.day === 29 && this.model.month === 1;
    }

    isDayDisabled(date: moment.Moment): boolean {
        return this.isSelectedMinorDayThanMinMarginal(date) || this.isSelectedGreaterDayThanMaxMarginal(date);
    }

    isSelectedGreaterDayThanMaxMarginal(date: moment.Moment): boolean {
        return (
            this.model.year &&
            this.model.year === this.max.year() &&
            date.month() === this.max.month() &&
            date.date() > this.max.date()
        );
    }

    isSelectedMinorDayThanMinMarginal(date: moment.Moment): boolean {
        return (
            this.model.year &&
            this.model.year === this.min.year() &&
            this.min.month() === date.month() &&
            this.min.date() > date.date()
        );
    }

    isSelectedGreaterMonthThanMaxMarginal(): boolean {
        return this.max.year() === this.model.year && this.model.month > this.max.month();
    }

    isSelectedMinorMonthThanMinMarginal(): boolean {
        return this.min.year() === this.model.year && this.min.month() > this.model.month;
    }

    undoPage(): void {
        if (this.isPreviousPage) {
            this.lastYearInMatrix = this.timeService.addYears(this.lastYearInMatrix, -this.yearsPerPage);
            this.calculateYears();
        }
    }

    addPage(): void {
        if (this.isNextPage) {
            this.lastYearInMatrix = this.timeService.addYears(this.lastYearInMatrix, this.yearsPerPage);
            this.calculateYears();
        }
    }

    public isSelected(date: moment.Moment): boolean {
        return this.model.year === date.year();
    }

    public isYearDisabled(date: moment.Moment): boolean {
        return this.min.year() > date.year() || date.year() > this.max.year();
    }
}
