import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private translate: TranslateService) {
        this.initTranslations();
    }

    private initTranslations(): void {
        this.translate.addLangs(['locale-en']);
        this.translate.setDefaultLang('locale-en');

        this.translate.use('locale-en');
    }
}
